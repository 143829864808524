<script setup lang="ts">
const isHidden = ref(false);

const props = withDefaults(
  defineProps<{
    name: string;
    code: string;
    desc: string;
    bgcolor?: string;
    textcolor?: string;
    icon?: string;
    isPreview?: boolean;
  }>(),
  {
    bgcolor: "#004884",
    textcolor: "#ffffff",
    icon: "",
    isPreview: false,
  },
);

const togglePosition = () => {
  isHidden.value = !isHidden.value;
};

const getImageSrc = (base64: string) => {
  if (!base64) return "";

  // Determine MIME type based on base64 prefix
  let mimeType = "";
  if (base64.startsWith("iVBORw0KGgo")) {
    mimeType = "image/png";
  } else if (base64.startsWith("/9j/")) {
    mimeType = "image/jpeg";
  } else if (base64.startsWith("PHN2Zy")) {
    mimeType = "image/svg+xml";
  } else if (base64.startsWith("UklGR")) {
    mimeType = "image/webp";
  } else {
    console.warn("Unknown image format");
    return "";
  }

  return `data:${mimeType};base64,${base64}`;
};

const description = ref<string[]>([]);

watch(
  () => props.desc,
  (newDesc) => {
    description.value = newDesc.split(";");
  },
  { immediate: true },
);
</script>

<template>
  <div
    :style="'background-color:' + props.bgcolor + '; color:' + props.textcolor"
    class="overflow-hidden flex items-center"
    :class="[
      `left-0 bottom-85px transform transition-transform duration-300 rd-r b-y b-r shadow-xl b-white py-1 w-[290px] flex items-center justify-center cursor-pointer`,
      {
        '-translate-x-[80%]': isHidden && props.icon,
        '-translate-x-[85%]': isHidden && !props.icon,
        'fixed z-100': !isPreview,
      },
    ]"
    @click="togglePosition"
  >
    <div class="flex flex-row items-center justify-around w-full relative">
      <div v-if="!isHidden" class="absolute right-1 top--2.5">x</div>
      <div class="text-center max-w-210px min-w-210px">
        <p class="text-lg font-bold line-clamp-1">{{ props.name }}</p>

        <template v-if="props.desc">
          <p v-for="(d, i) of description" :key="i" class="text-sm">
            {{ d }}
          </p>
        </template>
        <p v-else class="line-clamp-1">{{ props.code }}</p>
      </div>
      <div v-if="props.icon" class="px-1">
        <img
          class="size-8"
          :src="getImageSrc(props.icon)"
          alt="Promotion-Icon"
        />
      </div>
    </div>
  </div>
</template>
